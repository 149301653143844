@use "sass:color";
@use "colors";
@use "globals";

// Scrollbar styling.

$scrollbarWidth: 11px;
$scrollbarHeight: 7px;
$colorChange: 10%;
$scrollbarBorderRadius: 50px;
$scrollbarThumbColor: color.adjust(colors.$brandColor, $lightness: -$colorChange);
$scrollbarThumbColorOnHover: colors.$globalLinkHoverColor;

::-webkit-scrollbar {
  height: $scrollbarHeight; /* Height of horizontal scrollbar. */
  width: $scrollbarWidth; /* Width of vertical scrollbar. */
  border: none;
}

::-webkit-scrollbar-track {
  $margin: 1ch;
  background: colors.$colorBlueDark;
  border-radius: globals.$globalBorderRadius;
  margin-left: $margin;
  margin-right: $margin;
}

::-webkit-scrollbar-thumb:horizontal,
::-webkit-scrollbar-thumb:vertical {
  background: $scrollbarThumbColor;
  border-radius: $scrollbarBorderRadius;
}

::-webkit-scrollbar-thumb:horizontal:hover,
::-webkit-scrollbar-thumb:vertical:hover {
  background: $scrollbarThumbColorOnHover;
}
