@use "sass:color";
@use "globals";

// Table styling.
/*
$table-row-bg-color: lighten($background-color, 10%);
$blockquote-bg-color: lighten($background-color, 5%);
$table-header-bg-color: darken($background-color, 5%);
*/
$table-row-bg-color: #424759;
$table-header-bg-color: #1b1b1e;
table {
  border-radius: globals.$globalBorderRadius;
  th {
    border-radius: globals.$globalBorderRadius;
    background-color: color.adjust($table-header-bg-color, $alpha: -(globals.$globalAlpha));
    border: none;
  }
  td {
    border-radius: globals.$globalBorderRadius;
    background-color: color.adjust($table-row-bg-color, $alpha: -(globals.$globalAlpha));
  }
}
